<template>
  <div v-if="render_component" class="thank-you">
    <div v-if="temp_order_data && temp_order_data.payment_method !== payment_methods.paypal">
      <h2 v-if="!short_version" class="thank-you__title-1">{{ translations.thank_you_page.thank_you_message }}</h2>

      <div v-if="temp_order_data.payment_method === payment_methods.bank_transfer" class="thank-you__bank-transfer">
        <p>{{ translations.thank_you_page.bank_transfer_payment_method_message }}</p>
        <div>
          <span>{{ translations.thank_you_page.chosen_shipping_option }}:</span><span>{{ temp_order_data.shipping_method.title }}</span>
          <span>{{ translations.thank_you_page.chosen_payment_method }}:</span><span>{{ translations.thank_you_page[temp_order_data.payment_method] }}</span>
          <span>{{ translations.thank_you_page.amount_to_be_paid }}:</span><span><b>{{ temp_order_data.total_price }} {{ temp_order_data.currency }}</b></span>
          <span>{{ translations.thank_you_page.bank_account }}:</span><span><b>{{ shipping_options.project_address.bank_account }}</b></span>
          <span>{{ translations.thank_you_page.iban }}:</span><span>{{ shipping_options.project_address.iban }}</span>
          <span>{{ translations.thank_you_page.swift }}:</span><span>{{ shipping_options.project_address.swift }}</span>
          <span>{{ translations.thank_you_page.reference_number }}:</span><span><b>{{ temp_order_data.order_number }}</b></span>
        </div>
      </div>
      <div v-else>
        <p>{{ translations.thank_you_page.on_pickup_payment_method_message }}</p>
        <div class="thank-you__on-pickup">
          <div v-if="shipping_options.project_address.company">{{ shipping_options.project_address.company }}</div>
          <div>{{ shipping_options.project_address.street1 }}{{ shipping_options.project_address.street2 ? `, ${shipping_options.project_address.street2}` : "" }}</div>
          <div>{{ shipping_options.project_address.zip }}, {{
            shipping_options.project_address.state ? `${shipping_options.project_address.state}, ` : ""
          }}{{ get_country_by_code(shipping_options.project_address.country).name }}</div>
          <div v-if="shipping_options.project_address.phone">📞 {{ shipping_options.project_address.phone }}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2 class="thank-you__title-1">{{ translations.thank_you_page.second_message }}</h2>
      <p class="thank-you__title-2">{{ translations.thank_you_page.first_message }}</p>
    </div>

    <p v-if="!short_version" class="thank-you__title-3">
      <b>{{ translations.thank_you_page.order_number }}</b>
      {{ temp_order_data.order_number }}
    </p>
    <p v-if="!short_version" class="thank-you__title-2">{{ translations.thank_you_page.email }}</p>

    <div v-if="!short_version" class="thank-you__title-2">
      {{ translations.thank_you_page.go_to_order }}
      <router-link :to="`/order/${temp_order_data.order_id}`">
        <b>{{ translations.thank_you_page.here_link }}</b>.
      </router-link>
    </div>

    <router-link v-if="!short_version" to="/">
      <div class="button">
        <arrow />
        {{ translations.thank_you_page.homepage_redirect }}
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { home } from "../../../../data/other_constants"
import { CLIENT_STORE } from "../../constants/other"
import arrow from "../../../Shared/components/icon_components/arrow"
import { payment_methods, SHARED_STORE } from "../../../Shared/constants/other"
import { SET_WHOLE_BASKET } from "../../../Shared/stores/Shared/constants"
import { UPDATE_TEMP_ORDER_DATA } from "../../stores/Client/constants"
import get_country_by_code from "../../../Shared/methods/get_country_by_code"

export default {
  props: {
    short_version: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    arrow
  },
  data() {
    return {
      shopify_order_number: "",
      render_component: false,
      home,
      payment_methods
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "translations", 
      "use_shopify_data",
      "temp_order_data",
      "shipping_options"
    ]),
  },
  created() {
    if (this.short_version && this.temp_order_data) return this.render_component = true

    window.scrollTo(0, 0)
    if (this.use_shopify_data) {
      this.shopify_order_number = window.location.search.replace("?", "")

      this.shopify_order_number === "" ?
        this.$router.push({ path: "/" }) :
        this.handle_successful_shopify_order()
    }
    else if (!this.temp_order_data) return this.$router.push({ path: "/" })

    this.render_component = true
  },
  beforeDestroy() {
    if (!this.short_version) this.update_temp_order_data(null)
  },
  methods: {
    ...mapMutations(SHARED_STORE, {
      set_whole_basket: SET_WHOLE_BASKET
    }),
    ...mapMutations(CLIENT_STORE, {
      update_temp_order_data: UPDATE_TEMP_ORDER_DATA
    }),
    get_country_by_code,
    handle_successful_shopify_order() {
      this.set_whole_basket([])
    }
  },
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;

  .thank-you {
    max-width: $tablet;
    margin: 100px auto;
    padding: 40px 20px 0;
    border-radius: $border-radius;
    border: 1px solid var(--background_heavy);
    color: var(--font_heavy);

    @media (max-width: $tablet) {
      margin: 40px auto;
    }

    &__bank-transfer {
      margin: 20px auto 60px;
      max-width: 500px;

      div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
        font-size: 13px;
      }

      span {
        position: relative;
        color: var(--background_heavy);

        &:nth-child(odd) {
          font-weight: bold;
        }

        &:after {
          position: absolute;
          bottom: -5px;
          content: "";
          width: 100%;
          left: 0;
          height: 1px;
          background-color: var(--background_heavy);
          opacity: 0.2;
        }
      }
    }

    &__on-pickup {
      color: var(--background_heavy);
      margin: 20px auto 60px;
      text-align: center;
    }

    p {
      font-size: 17px;
    }

    .button {
      max-width: 300px;
      margin: 60px auto;

      svg {
        display: inline-block;
        height: 15px;
        margin: 0 10px 3px -10px;
        vertical-align: middle;
        transform: rotate(180deg);
        fill: var(--font_light);
        transition: 250ms ease-in-out;
      }

      &:hover svg {
        fill: var(--font_heavy);
      }
    }

    a {
      color: var(--font_heavy);
    }

    &__title {
      &-1 {
        text-align: center;
        font-weight: bold;
        margin: 0 0 50px;
      }
    }
  }
</style>
